import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { ProjectService } from 'src/app/services/project/project.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  constructor(
    private router: Router,
    private projectService: ProjectService,
    public loadingService: LoadingService,
    private responsive: BreakpointObserver
  ) { }

  public isTabletPortrait = false;
  public isTabletLandscape = false;
  public isHandsetPortrait = false;
  public isHandsetLandscape = false;
  public projects: any;
  public gridCols = 2;

  ngOnInit(): void {
    this.loadingService.show();
    this.getProjects();

    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        // Reset all breakpoints to false
        this.isTabletPortrait = false;
        this.isTabletLandscape = false;
        this.isHandsetPortrait = false;
        this.isHandsetLandscape = false;
        this.gridCols = 2;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.isTabletPortrait = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.isTabletLandscape = true;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.isHandsetPortrait = true;
          this.gridCols = 1;
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.isHandsetLandscape = true;
        }
      });

  }

  onImageLoad(project: any): void {
    project.loaded = true;
  }

  generateSlug(title: string, projectType: string): string {
    return `${title.toLowerCase().replace(/ /g, '-')}-${projectType.toLowerCase().replace(/ /g, '-')}`;
  }

  getProjects() {
    this.projectService.getProjects().subscribe({
      next: (projects: any) => {
        projects.data.sort((a: any, b: any) => {
          const dateA = new Date(a.attributes.date).getTime();
          const dateB = new Date(b.attributes.date).getTime();
          return dateB - dateA;
        });
        this.projects = projects.data.map((project: any) => ({
          ...project,
          loaded: false,
          slug: this.generateSlug(project.attributes.title, project.attributes.projectType),
        }));
        this.loadingService.hide();
      },
      error: (err) => {
        // Handle errors here if needed
        this.loadingService.hide();
      },
    });
  }
}