import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  private mailURL = 'https://broadconstruction.onrender.com/api/email';

  constructor(private http: HttpClient) {}

  sendEmail(mail: any) {
    return this.http.post(this.mailURL, mail);
    }
}
