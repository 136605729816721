<div class="spinner-container" *ngIf="loadingService.isLoading | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
<div class="container" *ngIf="!(loadingService.isLoading | async)">
  <div class="background-image">
    <img class="static-img" [src]="images[0].attributes.url" alt="" />
    <div id="scroll-wrapper">
      <div id="scroll-wrapper-inner">
        <div class="scroll-img">
          <img
            class="scroll-img"
            [ngClass]="{ 'scroll-img-handset-portrait': isHandsetPortrait }"
            src="assets/logo/logo-house.png"
            alt="broadconstruction logo"
          />
        </div>
        <div
          class="scroll-down"
          [ngClass]="{ 'scroll-down-handset-portrait': isHandsetPortrait }"
        ></div>
      </div>
    </div>
  </div>
  <div class="description">
    <div
      class="description-container"
      [ngClass]="{
        'description-container-small-screen':
          isTabletPortrait || isHandsetPortrait || isHandsetLandscape
      }"
      *ngIf="!(loadingService.isLoading | async)"
    >
      <div
        class="table"
        [ngClass]="{
          'table-small-screen':
            isTabletPortrait || isHandsetPortrait || isHandsetLandscape
        }"
      >
        <div
          class="title"
          [ngClass]="{
            'title-small-screen': isHandsetPortrait || isTabletPortrait
          }"
        >
          <h1 class="main-title">
            {{ project.title }}, {{ project.location }}
          </h1>
          <div
            class="subtitle"
            [ngClass]="{
              'subtitle-small-screen': isHandsetPortrait || isTabletPortrait
            }"
          >
            {{ project.projectType }}
          </div>
        </div>
        <div
          class="table-content"
          [ngClass]="{
            'table-content-small': isHandsetPortrait || isTabletPortrait
          }"
        >
          <div class="row">
            <div class="row-title">Location</div>
            <div>{{ project.location }}</div>
          </div>
          <div class="row">
            <div class="row-title">Scope</div>
            <div>{{ project.projectType }}</div>
          </div>
          <div class="row">
            <div class="row-title living-spaces">Living Spaces</div>
            <div class="buttons">
              <button
                [ngClass]="{
                  'button-small-screen':
                    isTabletPortrait || isHandsetPortrait || isHandsetLandscape
                }"
                mat-button
                (click)="openImage(images[0].attributes.url)"
              >
                Kitchen
              </button>
              |
              <button
                [ngClass]="{
                  'button-small-screen':
                    isTabletPortrait || isHandsetPortrait || isHandsetLandscape
                }"
                mat-button
                (click)="openImage(images[1].attributes.url ? images[1].attributes.url : images[0].attributes.url)"
              >
                Bathroom
              </button>
              |
              <button
                [ngClass]="{
                  'button-small-screen':
                    isTabletPortrait || isHandsetPortrait || isHandsetLandscape
                }"
                mat-button
                (click)="openImage(images[2].attributes.url ? images[2].attributes.url : images[0].attributes.url)"
              >
                Bedroom
              </button>
              |
              <button
                [ngClass]="{
                  'button-small-screen':
                    isTabletPortrait || isHandsetPortrait || isHandsetLandscape
                }"
                mat-button
                (click)="openImage(images[3].attributes.url ? images[3].attributes.url : images[0].attributes.url)"
              >
                Garden
              </button>
            </div>
          </div>
          <div class="row">
            <div *ngIf="project.review" class="row-title review-title">Client Review</div>
            <div class="review">{{ project.review }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="images" *ngIf="!(loadingService.isLoading | async)">
      <div class="image-container">
        <img
          loading="lazy"
          class="grid-img"
          [ngClass]="{
            'grid-img-small-screen':
              isTabletPortrait || isHandsetPortrait || isHandsetLandscape
          }"
          *ngFor="let image of images"
          [src]="image.attributes.url"
          alt="Image of a house built by Broad Construction in Bournemouth"
          #imgElement
        />
      </div>
    </div>
  </div>
</div>
