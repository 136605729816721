import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { cookieConfig } from 'src/app/services/cookie-consent/cookie-consent';
import { NewsService } from 'src/app/services/news/news.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public isTabletPortrait = false;
  public isTabletLandscape = false;
  public isHandsetPortrait = false;
  public isHandsetLandscape = false;
  public isChecked = false;
  public news: any;

  constructor(
    public router: Router,
    private responsive: BreakpointObserver,
    private ccService: NgcCookieConsentService,
    private newsService: NewsService
  ) {
  }

  ngOnInit() {
    this.getNews();
    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        // Reset all breakpoints to false
        this.isTabletPortrait = false;
        this.isTabletLandscape = false;
        this.isHandsetPortrait = false;
        this.isHandsetLandscape = false;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.isTabletPortrait = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.isTabletLandscape = true;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.isHandsetPortrait = true;
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.isHandsetLandscape = true;
        }
      });
  }

  getNews() {
    this.newsService.getNews().subscribe((news: any) => {
      news.data.sort((a: any, b: any) => {
        const dateA = new Date(a.attributes.Publish_date).getTime();
        const dateB = new Date(b.attributes.Publish_date).getTime();
        return dateB - dateA;
      });
      this.news = news.data.map((news: any) => ({
        ...news,
        slug: this.generateSlug(news.attributes.Title)
      }));
    });
  }

  generateSlug(title: string): string {
    return title.toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }

  openCookieService() {
    this.ccService.init(cookieConfig);
  }

  isOnHomePage(): boolean {
    return this.router.url === '/';
  }
  isOnServicesPage(): boolean {
    return this.router.url === '/services';
  }
  isOnProjectsPage(): boolean {
    return this.router.url === '/projects';
  }
  isOnProjectPage(): boolean {
    return this.router.url.startsWith('/projects');
  }
  isOnFaqPage(): boolean {
    return this.router.url === '/faq';
  }
  isOnContactPage(): boolean {
    return this.router.url === '/contact';
  }
  isOnAboutPage(): boolean {
    return this.router.url === '/about';
  }
}
