import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { ReviewService } from 'src/app/services/review/review.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
})
export class ReviewsComponent {
  reviews: any = [];
  currentReview = 0;
  private intervalSubscription: Subscription = new Subscription();
  private intervalCount = 0;
  private intervalTime = 8000;
  public carouselRestarting = true;
  public isTabletPortrait = false;
  public isTabletLandscape = false;
  public isHandsetPortrait = false;
  public isHandsetLandscape = false;

  constructor(
    private reviewService: ReviewService,
    public responsive: BreakpointObserver,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.getReviews();
    this.startCarouselInterval();

    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        // Reset all breakpoints to false
        this.isTabletPortrait = false;
        this.isTabletLandscape = false;
        this.isHandsetPortrait = false;
        this.isHandsetLandscape = false;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.isTabletPortrait = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.isTabletLandscape = true;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.isHandsetPortrait = true;
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.isHandsetLandscape = true;
        }
      });
  }

  getReviews() {
    this.reviewService.getReviews().subscribe({
      next: (reviews: any) => {
        this.reviews = reviews.data;
      },
      error: (err) => { },
    });
  }

  startCarouselInterval() {
    if (isPlatformBrowser(this.platformId)) {
    setTimeout(() => {
      this.carouselRestarting = false;
    }, 800);
      this.intervalSubscription = interval(this.intervalTime).subscribe(() => {
        if (!this.carouselRestarting) {
          this.onNextClick();
        }
        this.intervalCount++;
      });
    }
  }

  onPreviousClick() {
    if (this.carouselRestarting) this.restartCarouselInterval();

    this.carouselRestarting = true;
    const previous = this.currentReview - 1;
    this.currentReview = previous < 0 ? this.reviews.length - 1 : previous;
    this.restartCarouselInterval();
  }

  onNextClick() {
    if (this.carouselRestarting) this.restartCarouselInterval();

    this.carouselRestarting = true;
    const next = this.currentReview + 1;
    this.currentReview = next === this.reviews.length ? 0 : next;
    this.restartCarouselInterval();
  }

  restartCarouselInterval() {
    this.intervalSubscription.unsubscribe();
    this.startCarouselInterval();
  }

  goToReview(index: number) {
    this.currentReview = index;
    this.restartCarouselInterval();
  }
}
