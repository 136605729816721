import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { AddressService } from 'src/app/services/address/address.service';
import { MapStyle } from './map-style';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent {
  @ViewChild('mapInstance') map: GoogleMap | undefined;

  public infoContent: string = '';
  public loadMarkers = false;
  public isTabletPortrait = false;
  public isTabletLandscape = false;
  public isHandsetPortrait = false;
  public isHandsetLandscape = false;
  public mapHeight = '86vh';

  public options: google.maps.MapOptions = {
    center: { lat: 50.745, lng: -1.8785 }, // Center the map on Bournemouth, UK
    zoom: 12,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    styles: MapStyle,
  };

  public markerOptions: any = {
    optimized: false,
    icon: './../../../assets/icons/small-house-icon-grey.png',
  };

  public markers: {
    position: { lat: number; lng: number };
    address: string;
    options?: any;
  }[] = [];

  constructor(
    private addressService: AddressService,
    private responsive: BreakpointObserver
  ) {}

  ngOnInit() {
    this.getAddresses();

    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        // Reset all breakpoints to false
        this.isTabletPortrait = false;
        this.isTabletLandscape = false;
        this.isHandsetPortrait = false;
        this.isHandsetLandscape = false;
        this.mapHeight = '86vh';

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.isTabletPortrait = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.isTabletLandscape = true;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.isHandsetPortrait = true;
          this.mapHeight = '80vh';
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.isHandsetLandscape = true;
        }
      });

    setTimeout(() => {
      this.loadMarkers = true;
    }, 200);
  }

  @ViewChildren(MapInfoWindow)
  infoWindowsView!: QueryList<MapInfoWindow>;

  @ViewChild(MapInfoWindow)
  infoWindow!: MapInfoWindow;

  markerClick(marker: any, markerIndex: any, index: number) {
    this.markers.forEach((marker, ix) => {
      if (index === ix) {
        this.infoContent = marker.address;
        this.infoWindow.open(markerIndex);
      }
    });
  }

  getAddresses() {
    this.addressService.getAddresses().subscribe({
      next: (addresses: any) => {
        this.markers = addresses.data.map((address: any) => ({
          position: {
            lat: address.attributes.latitude,
            lng: address.attributes.longitude,
          },
          address: address.attributes.address,
        }));
      },
      error: (err) => {},
    });
  }
}
