import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { title } from 'process';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { NewsService } from 'src/app/services/news/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent {
  constructor(
    private newsService: NewsService,
    public loadingService: LoadingService,
    private router: Router,
    private responsive: BreakpointObserver
  ) { }
  news: any;
  public isTabletPortrait = false;
  public isTabletLandscape = false;
  public isHandsetPortrait = false;
  public isHandsetLandscape = false;

  ngOnInit() {
    this.loadingService.show();
    this.getNews();
    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        // Reset all breakpoints to false
        this.isTabletPortrait = false;
        this.isTabletLandscape = false;
        this.isHandsetPortrait = false;
        this.isHandsetLandscape = false;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.isTabletPortrait = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.isTabletLandscape = true;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.isHandsetPortrait = true;
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.isHandsetLandscape = true;
        }
      });
  }

  getNews() {
    this.newsService.getNews().subscribe((news: any) => {
      news.data.sort((a: any, b: any) => {
        const dateA = new Date(a.attributes.Publish_date).getTime();
        const dateB = new Date(b.attributes.Publish_date).getTime();
        return dateB - dateA;
      });
      this.news = news.data.map((news: any) => ({
        ...news,
        slug: this.generateSlug(news.attributes.Title)
      }));
      this.loadingService.hide();
    });
  }

  generateSlug(title: string): string {
    return title.toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }

}
