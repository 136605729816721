<div class="spinner-container" *ngIf="loadingService.isLoading | async">
    <mat-spinner color="accent"></mat-spinner>
</div>
<div class="careers-section" [ngClass]="{ 'careers-section-handset-portrait': isHandsetPortrait }">
    <div class="content" [ngClass]="{ 'content-handset-portrait': isHandsetPortrait }">
        <div class="content-container" [ngClass]="{ 'content-container-handset-portrait': isHandsetPortrait }">
            <div class="title">Available Positions</div>
            <div class="work-with-us">
                <h1 class="sub-title">Work with us</h1>
                <div class="left-content">We’re always on the lookout for talented people. So whether you’re an
                    experienced architect,
                    designer or contractor, we’d love to hear from you.</div>
                <a class="get-in-touch" routerLink="/contact" >Get in touch .</a>
            </div>
        </div>
    </div>

    <div class="jobs-list" [ngClass]="{'jobs-list-handset-portrait': isHandsetPortrait}">
        <div class="jobs">
            <ul>
                <li *ngFor="let job of jobs">
                    <div class="job-title">
                        <div class="job-title-title">{{ job.attributes.Title }}</div>
                        <div class="job-title-type">{{ job.attributes.Type }}</div>
                    </div>
                    <a aria-label="Send email about job" mat-stroked-button [href]="getMailToLink(job.attributes.Title)" class="enquire-button">Enquire
                        Now</a>
                </li>
            </ul>
        </div>
    </div>
</div>