<div class="background-image">
  <img src="assets/images/contact.webp" alt="broad construction warehouse" />
</div>
<div class="container" [ngClass]="{ 'container-handset-portrait': isHandsetPortrait }">
  <div class="contact-form">
    <h1 class="title">CONTACT</h1>
    <div class="hook">
      Have a project you’d like to get off the ground?
      Drop us a line or pop into our office in <a
        href="http://maps.google.com/?q=Unit 4B Wilverley Road, Christchurch, BH23 3RU, Dorset, England"
        target="_blank">Christchurch</a> to
      chat to the team in more detail.
    </div>
    <div class="form-container" ngClass="{'form-container-handset-portrait': isHandsetPortrait'}">
      <form class="form" [formGroup]="contactForm" [ngClass]="{ 'form-handset-portrait': isHandsetPortrait }">
        <div class="left" [ngClass]="{ 'left-handset-portrait': isHandsetPortrait }">
          <mat-form-field class="mat-form-field" [ngClass]="{ 'mat-form-field-handset-portrait': isHandsetPortrait }">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" name="name" required class="test"/>
            <mat-error *ngIf="contactForm.get('name')?.hasError('required')">Name is
              <strong>required</strong></mat-error>
            <mat-error *ngIf="contactForm.get('name')?.hasError('maxLength')">Name cannot exceed 50
              characters.</mat-error>
          </mat-form-field>
          <mat-form-field class="mat-form-field" [ngClass]="{ 'mat-form-field-handset-portrait': isHandsetPortrait }">
            <mat-label>E-mail</mat-label>
            <input matInput formControlName="email" name="email" />
            <mat-error *ngIf="contactForm.get('email')?.hasError('maxLength')">Name cannot exceed 100
              characters.</mat-error>
            <mat-error *ngIf="
                contactForm.get('email')?.hasError('email') &&
                !contactForm.get('email')?.hasError('required')
              ">Please enter a valid email address</mat-error>
            <mat-error *ngIf="contactForm.get('email')?.hasError('required')">Email is
              <strong>required</strong></mat-error>
          </mat-form-field>
          <mat-form-field class="mat-form-field" [ngClass]="{ 'mat-form-field-handset-portrait': isHandsetPortrait }">
            <mat-label>Contact Number</mat-label>
            <input matInput formControlName="contactNumber" name="contactNumber" required />
            <mat-error *ngIf="contactForm.get('contactNumber')?.hasError('required')">Contact number is
              <strong>required</strong></mat-error>
            <mat-error *ngIf="contactForm.get('contactNumber')?.hasError('pattern')">Please enter a valid contact number
              (numeric only).</mat-error>
            <mat-error *ngIf="contactForm.get('contactNumber')?.hasError('maxLength')">Contact number cannot exceed 15
              characters.</mat-error>
          </mat-form-field>
        </div>
        <div class="right" [ngClass]="{ 'right-handset-portrait': isHandsetPortrait }">
          <mat-form-field class="mat-form-field" [ngClass]="{ 'mat-form-field-handset-portrait': isHandsetPortrait }">
            <mat-label>Message</mat-label>
            <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput
              formControlName="message" name="message" required></textarea>
          </mat-form-field>
        </div>
      </form>
      <button aria-label="Send contact form" class="send-button" mat-raised-button (click)="sendForm()" [disabled]="!contactForm.valid">
        Send
      </button>
    </div>
    <div class="address">
      <div class="logo" [ngClass]="{ 'logo-handset-portrait': isHandsetPortrait }">
        <img src="assets/logo/logo-house.png" alt="broad construction logo" />
      </div>
      <a href="http://maps.google.com/?q=Unit 4B Wilverley Road, Christchurch, BH23 3RU, Dorset, England"
        target="_blank">
      <div class="address-details" [ngClass]="{ 'address-details-handset-portrait': isHandsetPortrait }">
        <div>Unit 4B Wilverley Road, Christchurch, BH23 3RU, Dorset, England</div>
      </div></a>
    </div>
    <div class="phone-number" [ngClass]="{ 'phone-number-handset-portrait': isHandsetPortrait }">
      <span class="call-to-action" [ngClass]="{ 'call-to-action-handset-portrait': isHandsetPortrait }">Get in touch to
        discuss your project</span>
      <br />
      <span class="pres" [ngClass]="{ 'pres-handset-portrait': isHandsetPortrait }">Email:</span>
      <div><a href="mailto:info@broadconstruction.co.uk" target="_blank">info&#64;broadconstruction.co.uk</a></div> <br />
      <span class="pres" [ngClass]="{ 'pres-handset-portrait': isHandsetPortrait }">Telephone:</span> <a
        href="tel:+441202901422">(+44) 1202 901422</a>
    </div>
  </div>
</div>