import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  private reviewURL = 'https://broadconstruction.onrender.com/api/reviews';

  httpOptions = {
    params: { populate: '*' },
  };

  constructor(private http: HttpClient) {}

  getReviews() {
    return this.http.get(this.reviewURL, this.httpOptions);
  }
}
