import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { MapComponent } from './components/map/map.component';
import { AboutComponent } from './components/about/about.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectComponent } from './components/project/project.component';
import { ContactComponent } from './components/contact/contact.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { NewsComponent } from './components/news/news.component';
import { NewComponent } from './components/new/new.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Residential Construction Management in Dorset | Broad Construction ',
      description:
        'Expert residential construction management services in Dorset. From planning to building, we handle it all. Contact us today!',
      canonical: '/',
      type: 'website'
    },
  },
  {
    path: 'areas-we-cover',
    component: MapComponent,
    data: {
      title: 'Find Us - Map and Locations | Broad Construction',
      description:
        'Find our office and explore the areas we proudly serve in Dorset, . Use our interactive map for detailed directions and service locations.',
      canonical: '/areas-we-cover',
      type: 'website'
    },
  },
  {
    path: 'about',
    component: AboutComponent,
    data: {
      title: 'About Us - Experienced Construction Managers | Broad Construction',
      description:
        'Learn about our company, our team, and our commitment to delivering high-quality residential construction projects.',
      canonical: '/about',
      type: 'website'
    },
  },
  {
    path: 'projects',
    component: ProjectsComponent,
    data: {
      title: 'Our Projects - Showcase of Expertise | Broad Construction',
      description:
        'Explore our diverse portfolio of residential projects, including stunning renovations and expertly crafted new builds. See our exceptional work.',
      canonical: '/projects',
      type: 'website'
    },
  },
  {
    path: 'projects/:id/:title',
    component: ProjectComponent,
    data: {
      title: 'Project Details | Broad Construction',
      description:
        'Explore detailed information about our individual projects, including images, specs, and client testimonials.',
      canonical: '/projects',
      type: 'article'
    },
  },
  {
    path: 'news/:id/:title',
    component: NewComponent,
    data: {
      title: 'News Article | Broad Construction',
      description:
        'Read the latest updates and news from Broad Construction, including insights into our projects and industry trends.',
      canonical: '/news',
      type: 'article'
    },
  },
  {
    path: 'news',
    component: NewsComponent,
    data: {
      title: 'Latest News - Insights and Updates | Broad Construction',
      description:
        'Stay informed with the latest news, updates, and insights from the residential construction industry.',
      canonical: '/news',
      type: 'website'
    },
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: {
      title: 'Contact Us - Get in Touch | Broad Construction',
      description:
        'Contact Broad Construction with questions, project ideas, or to schedule a consultation. Let’s discuss how we can bring your vision to life.',
      canonical: '/contact',
      type: 'website'
    },
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: {
      title: 'Privacy Policy | Broad Construction',
      description:
        'Discover how Broad Construction protects your personal data. Read our comprehensive privacy policy detailing our practices and safeguards.',
      canonical: '/privacy',
      type: 'website'
    },
  },
  {
    path: 'jobs',
    component: JobsComponent,
    data: {
      title: 'Careers - Join Our Team | Broad Construction',
      description:
        'Explore career opportunities at Broad Construction and become a part of our talented residential construction team.',
      canonical: '/jobs',
      type: 'website'
    },
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking', urlUpdateStrategy: 'eager',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
