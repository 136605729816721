<div class="spinner-container" *ngIf="loadingService.isLoading | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
<div class="container" *ngIf="!(loadingService.isLoading | async)">
  <div class="news-hero" [ngClass]="{'news-hero-handset-portrait': isHandsetPortrait || isTabletPortrait || isHandsetLandscape}">
    <h1 class="news-title">{{ news.Title }}</h1>
    <div>- {{ news.Publish_date | date }}</div>
  </div>
  <div class="background-image">
    <img class="static-img" [src]="images[0].attributes.url" alt="image relating to the news in the article" />
    <div id="scroll-wrapper">
      <div id="scroll-wrapper-inner">
        <div class="scroll-img">
          <img
            class="scroll-img"
            [ngClass]="{ 'scroll-img-handset-portrait': isHandsetPortrait }"
            src="assets/logo/logo-house.png"
            alt="broadconstruction logo"
          />
        </div>
        <div
          class="scroll-down"
          [ngClass]="{ 'scroll-down-handset-portrait': isHandsetPortrait }"
        ></div>
      </div>
    </div>
  </div>
  <div class="images" *ngIf="!(loadingService.isLoading | async)">
    <div class="image-container">
      <img
        loading="lazy"
        class="grid-img"
        [ngClass]="{
          'grid-img-small-screen':
            isTabletPortrait || isHandsetPortrait || isHandsetLandscape
        }"
        *ngFor="let image of images"
        [src]="image.attributes.url"
        [alt]="news.description"
      />
      <div
        class="description"
        [ngClass]="{
          'description-handset-portrait':
            isTabletPortrait || isHandsetPortrait || isHandsetLandscape
        }"
        [innerHTML] = news.Description
      >
      </div>
    </div>
  </div>
</div>
