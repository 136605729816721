import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { JobService } from 'src/app/services/job/job.service';
import { LoadingService } from 'src/app/services/loading/loading.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent {
  public isTabletPortrait = false;
  public isTabletLandscape = false;
  public isHandsetPortrait = false;
  public isHandsetLandscape = false;

  ngOnInit() {
    this.getNews();
    this.loadingService.show();
    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        // Reset all breakpoints to false
        this.isTabletPortrait = false;
        this.isTabletLandscape = false;
        this.isHandsetPortrait = false;
        this.isHandsetLandscape = false;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.isTabletPortrait = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.isTabletLandscape = true;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.isHandsetPortrait = true;
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.isHandsetLandscape = true;
        }
      });
  }

  constructor(private responsive: BreakpointObserver,
    private jobService: JobService, public loadingService: LoadingService) { }

  public jobs: any;

  openEnquiry(title: string) {
    console.log(title);
  }

  getNews() {
    this.jobService.getJobs().subscribe((jobs: any) => {
      jobs.data.sort((a: any, b: any) => {
        const dateA = new Date(a.attributes.Publish_date).getTime();
        const dateB = new Date(b.attributes.Publish_date).getTime();
        return dateB - dateA;
      });
      this.jobs = jobs.data;
      this.loadingService.hide();
    });
  }



  getMailToLink(jobTitle: string): string {
    const encodedTitle = encodeURIComponent(jobTitle);
    return `mailto:info@broadconstruction.co.uk?subject=${encodedTitle}&body=I am interested in this job position.`;
  }
}
