import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AboutService } from 'src/app/services/about/about.service';
import { LoadingService } from 'src/app/services/loading/loading.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
  constructor(
    private responsive: BreakpointObserver,
    private aboutService: AboutService,
    public loadingService: LoadingService,
  ) {}
  public aboutData: any;
  public images: any;
  public isTabletPortrait = false;
  public isTabletLandscape = false;
  public isHandsetPortrait = false;
  public isHandsetLandscape = false;

  ngOnInit(): void {
    this.loadingService.show();
    this.getAboutData();
    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        // Reset all breakpoints to false
        this.isTabletPortrait = false;
        this.isTabletLandscape = false;
        this.isHandsetPortrait = false;
        this.isHandsetLandscape = false;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.isTabletPortrait = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.isTabletLandscape = true;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.isHandsetPortrait = true;
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.isHandsetLandscape = true;
        }
      });
  }

  getAboutData() {
    this.aboutService.getImage().subscribe({
      next: (about: any) => {
        this.aboutData = about.data.attributes;
        this.images = this.aboutData.image.data;
        this.loadingService.hide();
      },
      error: (err) => {
        console.error('Error loading about data:', err);
        this.loadingService.hide();
      }
    });
  }
}
