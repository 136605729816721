import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'https://broadconstruction-demo.netlify.app/',
  },
  position: 'bottom',
  theme: 'edgeless',
  palette: {
    popup: {
      background: 'rgba(255,255,255,0.6)',
      text: '#000000',
      link: '#000000',
    },
    button: {
      background: 'rgba(204,224,89,0.6)',
      text: '#000000',
      border: 'transparent',
    },
  },
  type: 'opt-out',
  content: {
    message:
      'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    deny: 'Refuse cookies',
    link: 'Learn more',
    href: 'https://cookiesandyou.com',
    policy: 'Cookie Policy',
  },
};
