import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JobService {
  private jobURL = 'https://broadconstruction.onrender.com/api/jobs';
  httpOptions: any;

  constructor(private http: HttpClient) {}

  getJobs() {
    const params = new HttpParams()
      .set('pagination[page]', 1)
      .set('pagination[pageSize]', 100);
    return this.http.get(this.jobURL, { params });
  }
}
