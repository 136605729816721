import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { MapStyle } from '../map/map-style';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MailService } from 'src/app/services/mail/mail.service';
import { MatSnackBar, MatSnackBarRef, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  constructor(
    public loadingService: LoadingService,
    private translate: TranslateService,
    private responsive: BreakpointObserver,
    private formBuilder: FormBuilder,
    private mailService: MailService, 
    private _snackBar: MatSnackBar
  ) { }
  public isTabletPortrait = false;
  public isTabletLandscape = false;
  public isHandsetPortrait = false;
  public isHandsetLandscape = false;
  public questions: any;
  public mapHeight = '40vh';
  public options: google.maps.MapOptions = {
    center: { lat: 50.74064, lng: -1.78518 }, // Center the map on Bournemouth, UK
    zoom: 14,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
  };
  public formData: any = {};

  contactForm: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required, Validators.maxLength(50)]],
    email: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(100)],
    ],
    contactNumber: [
      '',
      [
        Validators.required,
        Validators.pattern('[0-9]+'),
        Validators.maxLength(15),
      ],
    ],
    message: ['', [Validators.required, Validators.maxLength(1000)]],
  });

  ngOnInit(): void {
    this.loadingService.show();
    this.loadPage();

    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        // Reset all breakpoints to false
        this.isTabletPortrait = false;
        this.isTabletLandscape = false;
        this.isHandsetPortrait = false;
        this.isHandsetLandscape = false;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.isTabletPortrait = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.isTabletLandscape = true;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.isHandsetPortrait = true;
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.isHandsetLandscape = true;
        }
      });
  }

  sendForm() {
    if (this.contactForm.valid) {
      const subjectName = this.contactForm.get('name')!.value
      const mailForm = {
        to: 'info@broadconstruction.co.uk',
        from: 'yves.animol@gmail.com',
        replyTo: 'yves.animol@gmail.com',
        subject: 'New email from ' + subjectName,
        text: this.contactForm.get('message')!.value + '\n phone number: ' + this.contactForm.get('contactNumber')!.value + '\n email: ' + this.contactForm.get('email')!.value,
        html: this.contactForm.get('message')!.value + '\n phone number: ' + this.contactForm.get('contactNumber')!.value + '\n email: ' + this.contactForm.get('email')!.value,
      };
      this.mailService.sendEmail(mailForm).subscribe({
        next: (res: any) => {
          this.contactForm.reset();
          this.openSnackBar(subjectName)
        },
        error: (err: any) => {
        }
      });
    } else {
      console.error(
        'Form is not valid. Please fill in all the required fields.'
      );
    }
  }

  openSnackBar(name: string) {
    this._snackBar.open('Thank you ' + name + ', your message has been sent', 'OK')
  }

  loadPage() {
    this.loadingService.hide();
  }
}
