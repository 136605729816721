<div class="spinner-container" *ngIf="loadingService.isLoading | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
<div class="carousel" *ngIf="!(loadingService.isLoading | async)">
  <div class="slide" *ngFor="let image of images; let i = index">
    <ng-container *ngIf="i === currentImage">
      <img
        [src]="image.attributes.url"
        alt="Beautiful picture of a house built by Broad Construction"
        fill
        class="project"
        priority
        (touchstart)="swipe($event, 'start')"
        (touchend)="swipe($event, 'end')"
        @fadeAnimation
      />
    </ng-container>
    <!-- controls -->
  </div>
  <button 
    aria-label="Previous slide"
    class="control prev"
    (click)="onPreviousClick()"
    [disabled]="carouselRestarting"
  >
    <span class="arrow left"></span>
  </button>
  <button 
    aria-label="Previous slide"
    class="control next"
    (click)="onNextClick()"
    [disabled]="carouselRestarting"
  >
    <span class="arrow right"></span>
  </button>
  <div
    class="slides"
    [ngClass]="{ 'slides-handset-portrait': isHandsetPortrait }"
    *ngIf="false"
  >
    <div *ngFor="let image of images; let i = index">
      <div
        class="slide-line"
        [ngClass]="{
          active: i === currentImage,
          'slide-line-smaller': isHandsetPortrait
        }"
        (click)="goToProject(i)"
      ></div>
    </div>
  </div>
  <div id="scroll-wrapper">
    <div id="scroll-wrapper-inner">
      <div class="scroll-img">
        <img
          class="scroll-img"
          [ngClass]="{ 'scroll-img-handset-portrait': isHandsetPortrait }"
          src="assets/logo/logo-house.png"
          alt="broad construction logo"
        />
      </div>
      <div
        class="scroll-down"
        [ngClass]="{ 'scroll-down-handset-portrait': isHandsetPortrait }"
      ></div>
    </div>
  </div>
</div>
