import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {
  NgcCookieConsentService,
  NgcStatusChangeEvent
} from 'ngx-cookieconsent';
import { filter, map, Subscription } from 'rxjs';
import { cookieConfig } from './services/cookie-consent/cookie-consent';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { SEOService } from './services/seo/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private statusChangeSubscription!: Subscription;
  private hostName = 'https://broadconstruction.co.uk';

  constructor(
    private ccService: NgcCookieConsentService,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(() => !!this.activatedRoute.firstChild),
        map(() => {
          let route = this.activatedRoute.firstChild;

          // Traverse to the deepest child route
          while (route?.firstChild) {
            route = route.firstChild;
          }
          return route?.snapshot;
        })
      )
      .subscribe((snapshot) => {
        const data = snapshot?.data;

        // Set dynamic title and meta tags
        if (data && data['title']) {
          this.titleService.setTitle(data['title']);
        }

        if (data && data['description']) {
          this.metaService.updateTag({ name: 'description', content: data['description'] });
        }

        // Handle dynamic canonical URL
        if (data?.['canonical']) {
          const id = snapshot?.params['id'];
          const title = snapshot?.params['title'];

          let canonicalUrl = this.hostName + data['canonical'];
          if (id && title) {
            canonicalUrl = `${canonicalUrl}/${id}/${title}`;
          }
          this.metaService.updateTag({ property: 'og:url', content: canonicalUrl });
          this.seoService.updateCanonicalUrl(canonicalUrl);
        }

        // Set Open Graph tags
        if (data && data['title'] && data['description'] && data['type']) {
          this.metaService.updateTag({ property: 'og:title', content: data['title'] });
          this.metaService.updateTag({ property: 'og:description', content: data['description'] });
          this.metaService.updateTag({ property: 'og:type', content: data['type'] });
        }
      });

    if (this.checkIfCookieExists()) {
      this.ccService.destroy();
    }

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        this.cookieService.set('consent', event.status);
        this.ccService.destroy();
      }
    );
  }
  checkIfCookieExists(): boolean {
    return !!this.cookieService.get('consent');
  }

  ngAfterViewInit() { }

  initCookieService() {
    this.ccService.init(cookieConfig);
  }

  ngOnDestroy() {
    this.statusChangeSubscription.unsubscribe();
  }

  title = 'Broad Construction';
}
