<div class="toolbar-background" [ngClass]="{
    'show-background': isBackgroundVisible,
    'show-background-tablet-landscape':
      isBackgroundVisible && isTabletLandscape,
    'show-background-handset-landscape':
      isBackgroundVisible && isHandsetLandscape,
    'show-background-tablet-portrait': isBackgroundVisible && isTabletPortrait,
    'show-background-handset-portrait': isHandsetPortrait,
    'toolbar-background-tablet-landscape': isTabletLandscape,
    'toolbar-background-handset-landscape': isHandsetLandscape,
    'toolbar-background-tablet-portrait': isTabletPortrait,
    'toolbar-background-handset-portrait': isHandsetPortrait,
    'show-full-background': menuToggled
  }"></div>
<div class="container">
  <mat-toolbar class="toolbar" [ngClass]="{
    'toolbar-tablet-landscape': isTabletLandscape,
    'toolbar-handset-landscape': isHandsetLandscape,
    'toolbar-tablet-portrait': isTabletPortrait,
    'toolbar-handset-portrait': isHandsetPortrait,
  }">
    <div class="toolbar-retracted">
      <img [src]="isHandsetPortrait ? brownLogo : whiteLogo" alt="Logo of Broad Construction" class="logo" [ngClass]="{
          'logo-tablet-landscape': isTabletLandscape ,
          'logo-handset-landscape': isHandsetLandscape,
          'logo-tablet-portrait': isTabletPortrait,
          'logo-handset-portrait': isHandsetPortrait,
        }" routerLink="/" href="/" (click)="toggleMenu('close')" />
      <span class="big-spacer"></span>
      <div class="buttons" [ngClass]="{ 'buttons-homepage': isOnHomePage() || isOnProjectPage() }"
        *ngIf="!isHandsetPortrait">
        <button class="button" [ngClass]="{
            'button-tablet-landscape': isTabletLandscape,
            'button-handset-landscape': isHandsetLandscape,
            'button-tablet-portrait': isTabletPortrait,
            'button-handset-portrait': isHandsetPortrait,
            'active-button': isActive('/about') || isOnHomePage()
          }" mat-button>
          <a routerLink="/about">ABOUT</a>
        </button>
        <button class="button" [ngClass]="{
            'button-tablet-landscape': isTabletLandscape,
            'button-handset-landscape': isHandsetLandscape,
            'button-tablet-portrait': isTabletPortrait,
            'button-handset-portrait': isHandsetPortrait,
            'active-button': isActive('/projects') || isOnHomePage()
          }" mat-button>
          <a routerLink="/projects">PROJECTS</a>
        </button>
        <button class="button" [ngClass]="{
            'button-tablet-landscape': isTabletLandscape,
            'button-handset-landscape': isHandsetLandscape,
            'button-tablet-portrait': isTabletPortrait,
            'button-handset-portrait': isHandsetPortrait,
            'active-button': isActive('/map') || isOnHomePage()
          }" mat-button>
          <a routerLink="/areas-we-cover">MAP</a></button><button class="button" [ngClass]="{
            'button-tablet-landscape': isTabletLandscape,
            'button-handset-landscape': isHandsetLandscape,
            'button-tablet-portrait': isTabletPortrait,
            'button-handset-portrait': isHandsetPortrait,
            'active-button': isActive('/news') || isOnHomePage()
          }" mat-button>
          <a routerLink="/news">NEWS</a>
        </button>
        <button class="button" [ngClass]="{
            'button-tablet-landscape': isTabletLandscape,
            'button-handset-landscape': isHandsetLandscape,
            'button-tablet-portrait': isTabletPortrait,
            'button-handset-portrait': isHandsetPortrait,
            'active-button': isActive('/contact') || isOnHomePage()
          }" mat-button>
          <a routerLink="/contact">CONTACT</a>
        </button>
      </div>
      <button mat-icon-button class="icon-button" *ngIf="isHandsetPortrait" (click)="toggleMenu()">
        <mat-icon *ngIf="!menuToggled" class="icon" @inOutAnimation>menu</mat-icon>
        <mat-icon *ngIf="menuToggled" class="icon" @inOutAnimation>close</mat-icon>
      </button>
    </div>
    <div class="menu" *ngIf="menuToggled">
      <div class="menu-button" @fadeIn>
        <button mat-button class="menu-item" (click)="toggleMenu()">
          <a routerLink="/about">About</a>
        </button>
      </div>
      <div class="menu-button" @fadeIn2>
        <button mat-button class="menu-item" (click)="toggleMenu()">
          <a routerLink="/projects">Projects</a>
        </button>
      </div>
      <div class="menu-button" @fadeIn3>
        <button mat-button class="menu-item" (click)="toggleMenu()">
          <a routerLink="/areas-we-cover">Map</a>
        </button>
      </div>
      <div class="menu-button" @fadeIn4>
        <button mat-button class="menu-item" (click)="toggleMenu()">
          <a routerLink="/news">News</a>
        </button>
      </div>
      <div class="menu-button" @fadeIn5>
        <button mat-button class="menu-item" (click)="toggleMenu()">
          <a routerLink="/contact">Contact</a>
        </button>
      </div>
    </div>
  </mat-toolbar>
</div>