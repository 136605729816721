import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private projectURL = 'https://broadconstruction.onrender.com/api/news';

  httpOptions = {
    params: { populate: '*' },
  };

  constructor(private http: HttpClient) {}

  getNew(id: number) {
    return this.http.get(this.projectURL  + '/' + id, this.httpOptions);
  }

  getNews() {
    return this.http.get(this.projectURL, this.httpOptions);
  }

}


