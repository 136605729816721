import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private projectURL = 'https://broadconstruction.onrender.com/api/projects';

  httpOptions = {
    params: { populate: '*' },
  };

  constructor(private http: HttpClient) {}

  getProject(id: number) {
    return this.http.get(this.projectURL  + '/' + id, this.httpOptions);
  }

  getProjects() {
    return this.http.get(this.projectURL, this.httpOptions);
  }
}
