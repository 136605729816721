<div class="spinner-container" *ngIf="loadingService.isLoading | async">
  <mat-spinner></mat-spinner>
</div>
<div class="container" *ngIf="!(loadingService.isLoading | async)">
  <div class="container"
    [ngClass]="{ 'container-handset-portrait': isTabletPortrait || isHandsetPortrait || isHandsetLandscape }">
    <h1 class="title"
      [ngClass]="{ 'title-handset-portrait': isTabletPortrait || isHandsetPortrait || isHandsetLandscape }">
      News
    </h1>
    <div class="news"
      [ngClass]="{ 'news-handset-portrait': isTabletPortrait || isHandsetPortrait || isHandsetLandscape }">
      <div class="new"
        [ngClass]="{ 'new-handset-portrait': isTabletPortrait || isHandsetPortrait || isHandsetLandscape }"
        *ngFor="let news of news">
        <div class="img-wrapper">
          <img class="image" src="{{ news.attributes.Image.data[0].attributes.formats.medium.url }}"
            alt="image associated to the news" [routerLink]="['/news', news.id, news.slug]" />
        </div>
        <a class="news-link" [routerLink]="['/news', news.id, news.slug]">
          <div class="date">
            <mat-icon class="icon">calendar_month</mat-icon>
            {{ news.attributes.Publish_date | date }}
          </div>

          <div class="news-title">{{ news.attributes.Title }}</div>
          <div class="description">{{ news.attributes.Description }}</div>
        </a>
      </div>
    </div>
  </div>
  <div class="instagram">
    <div class="instagram-content">
      <iframe src="https://widget.tagembed.com/2156370" style="width:100%;height:600px;border:none;"></iframe>
    </div>
  </div>
</div>