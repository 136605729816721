import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { NewsService } from 'src/app/services/news/news.service';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss'],
})
export class NewComponent {
  private newsId: number = 0;
  public news: any;
  public images: any;
  public isTabletPortrait = false;
  public isTabletLandscape = false;
  public isHandsetPortrait = false;
  public isHandsetLandscape = false;

  constructor(
    private route: ActivatedRoute,
    private newsService: NewsService,
    public loadingService: LoadingService,
    private responsive: BreakpointObserver
  ) { }
  ngOnInit() {
    this.loadingService.show();
    this.getNewsId();
    this.getNews(this.newsId);
    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        // Reset all breakpoints to false
        this.isTabletPortrait = false;
        this.isTabletLandscape = false;
        this.isHandsetPortrait = false;
        this.isHandsetLandscape = false;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.isTabletPortrait = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.isTabletLandscape = true;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.isHandsetPortrait = true;
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.isHandsetLandscape = true;
        }
      });
  }

  getNewsId() {
    this.route.params.subscribe((params) => {
      this.newsId = params['id'];
    });
  }

  getNews(id: number) {
    this.newsService.getNew(id).subscribe({
      next: (news: any) => {
        this.news = news.data.attributes;
        this.images = news.data.attributes.Image.data;
        this.loadingService.hide();
      },
      error: (err) => { },
    });
  }
}
