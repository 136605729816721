<div class="areas-we-cover">
  <section class="intro">
    <h1>Areas We Cover</h1>
    <p>
      Broad Construction is proud to serve clients across Dorset and the South Coast, offering top-quality services in
      new builds, extensions, loft conversions, and full house refurbishments. Below are the areas we specialize in,
      along with details about the unique services we provide in each location.
    </p>
  </section>

  <section class="area-descriptions" [ngClass]="{ 'area-descriptions-handset-portrait': isHandsetPortrait || isHandsetLandscape || isTabletPortrait }">
    <div class="area" [ngClass]="{ 'area-handset-portrait': isHandsetPortrait || isHandsetLandscape || isTabletPortrait }">
      <h2>Christchurch</h2>
      <p>
        We specialize in modern new builds, home renovations, and extensions in Christchurch, ensuring exceptional
        craftsmanship tailored to your needs.
      </p>
    </div>
    <div class="area" [ngClass]="{ 'area-handset-portrait': isHandsetPortrait || isHandsetLandscape || isTabletPortrait }">
      <h2>Hengistbury Head</h2>
      <p>
        Our team has extensive experience in delivering high-quality construction services in Hengistbury Head,
        including luxury new builds and refurbishments.
      </p>
    </div>
    <div class="area" [ngClass]="{ 'area-handset-portrait': isHandsetPortrait || isHandsetLandscape || isTabletPortrait }">
      <h2>Southbourne</h2>
      <p>
        Providing bespoke home renovations and extensions in Southbourne, we help homeowners create their dream spaces.
      </p>
    </div>
    <div class="area" [ngClass]="{ 'area-handset-portrait': isHandsetPortrait || isHandsetLandscape || isTabletPortrait }">
      <h2>Mudeford</h2>
      <p>
        Mudeford residents rely on us for our expertise in full house refurbishments and loft conversions, tailored to
        coastal living.
      </p>
    </div>
    <div class="area" [ngClass]="{ 'area-handset-portrait': isHandsetPortrait || isHandsetLandscape || isTabletPortrait }">
      <h2>Highcliffe</h2>
      <p>
        Highcliffe is one of our primary service areas, where we deliver new build management and high-end construction
        projects.
      </p>
    </div>
    <div class="area" [ngClass]="{ 'area-handset-portrait': isHandsetPortrait || isHandsetLandscape || isTabletPortrait }">
      <h2>Milford On Sea</h2>
      <p>
        In Milford On Sea, we excel in extensions and renovations, bringing modern design to this picturesque coastal
        community.
      </p>
    </div>
    <div class="area" [ngClass]="{ 'area-handset-portrait': isHandsetPortrait || isHandsetLandscape || isTabletPortrait }">
      <h2>Barton on Sea</h2>
      <p>
        Our work in Barton on Sea includes loft conversions, extensions, and comprehensive house refurbishments.
      </p>
    </div>
    <div class="area" [ngClass]="{ 'area-handset-portrait': isHandsetPortrait || isHandsetLandscape || isTabletPortrait }">
      <h2>Chewton Bunny</h2>
      <p>
        From new builds to tailored renovations, we proudly serve the unique community of Chewton Bunny with premium
        construction services.
      </p>
    </div>
  </section>

  <div class="map">
    <google-map [options]="options" width="100vw" [height]="mapHeight" #mapInstance="googleMap">
      <div *ngIf="loadMarkers">
        <map-marker *ngFor="let marker of markers; let i = index" [position]="marker.position"
          (mapClick)="markerClick(marker, markerIndex, i)" [options]="markerOptions" #markerIndex="mapMarker">
          <map-info-window>
            <div class="map-info-content">
              <div class="infowindow-title" [innerHTML]="infoContent"></div>
              <div></div>
              <button aria-label="Go to projects page" mat-raised-button class="infowindow-button">
                <a routerLink="/projects">See Projects</a>
              </button>
            </div>
          </map-info-window>
        </map-marker>
      </div>
    </google-map>
  </div>
</div>