<div class="spinner-container" *ngIf="loadingService.isLoading | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
<div class="container" *ngIf="!(loadingService.isLoading | async)">
  <div class="section section-1">
    <img [src]="aboutData.image.data[0].attributes.url" fill class="project" alt="Construction plans on a wooden desk" priority />
    <div class="middle-band">
      <div class="band-content" [ngClass]="{ 'band-content-handset-portrait': isHandsetPortrait }">
        <div class="project-type" [ngClass]="{ 'project-type-handset-portrait': isHandsetPortrait }">
          <h1 class="title" [ngClass]="{ 'title-handset-portrait': isHandsetPortrait }">About Broad Construction:  Residential Construction Experts in Dorset</h1>
          We are a specialist residential construction management
          company based in Christchurch, Dorset. <br> <br>
          Established in 2016, our team has a wealth of experience
          in helping people like you make their dream space a reality. <br> <br>

          Whether it’s a new-build property or renovating a second
          home, we offer a flexible approach that’s tailored to your
          lifestyle. <br> <br>
          From initial concepts and planning to design
          and build, we’re with you every step of the way.
        </div>
      </div>
    </div>
  </div>
  <div class="section maintenance" [ngClass]="{
    'section-handset-portrait':
      isHandsetPortrait ||
      isTabletPortrait ||
      isTabletLandscape ||
      isHandsetLandscape, 'first': isHandsetPortrait ||
      isTabletPortrait ||
      isTabletLandscape ||
      isHandsetLandscape
  }">
    <div class="right-content" [ngClass]="{
      'right-content-handset-portrait':
        isHandsetPortrait ||
        isTabletPortrait ||
        isTabletLandscape ||
        isHandsetLandscape
    }">
      <div class="content-right" [ngClass]="{
        'content-right-handset-portrait':
          isHandsetPortrait ||
          isTabletPortrait ||
          isTabletLandscape ||
          isHandsetLandscape
      }">
        <div class="right-title">Bringing Expertise
          to Every Build</div>
        <div class="first-row">
          Our team is born and bred in Dorset, so we have a
          deep understanding of the local area and pride
          ourselves on our strong connections with
          trusted and vetted suppliers. <br> <br>
          From finding an architect to bring your vision
          to life to sourcing the finest materials,
          we’ll bring together the best people and
          products for the job.
        </div>
      </div>
    </div>
    <div class="left-content" [ngClass]="{
      'left-content-handset-portrait':
        isHandsetPortrait ||
        isTabletPortrait ||
        isTabletLandscape ||
        isHandsetLandscape
    }">
      <img loading="lazy" [src]="aboutData.image.data[1].attributes.url" alt="Furnished beautifully crafted kitchen" />
    </div>
  </div>
  <div class="section maintenance" [ngClass]="{
      'section-handset-portrait':
        isHandsetPortrait ||
        isTabletPortrait ||
        isTabletLandscape ||
        isHandsetLandscape
    }">
    <div class="left-content" [ngClass]="{
        'left-content-handset-portrait':
          isHandsetPortrait ||
          isTabletPortrait ||
          isTabletLandscape ||
          isHandsetLandscape
      }">
      <img loading="lazy" [src]="aboutData.image.data[2].attributes.url" alt="Broad construction warehouse" />
    </div>
    <div class="right-content" [ngClass]="{
        'right-content-handset-portrait':
          isHandsetPortrait ||
          isTabletPortrait ||
          isTabletLandscape ||
          isHandsetLandscape
      }">
      <div class="content-right" [ngClass]="{
        'content-right-handset-portrait':
          isHandsetPortrait ||
          isTabletPortrait ||
          isTabletLandscape ||
          isHandsetLandscape
      }">
        <div class="right-title">Blank Canvas to Bespoke Design</div>
        <div>
          <div class="first-row">
            We can offer a complete residential turnkey experience,
            overseeing the entire build process and managing
            every aspect of your project. <br> <br>
            So all you have to concentrate on is the excitement
            of move-in day. </div>
        </div>
        <div class="right-title">Committed to Quality Craftmanship</div>
        <div>
          <div class="first-row">
            We will treat your property like our own, bringing the same care
            and attention to every project. <br> <br>
            Once we’ve worked with you to understand the type of space you’re
            looking to create we’ll give you some design options that are
            completely tailored to you. <br> <br>
            For your peace of mind, our team will be on the ground throughout
            the process to make sure everything is compliant, adhering to regulations,
            safe and on track. </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section maintenance" [ngClass]="{
    'section-handset-portrait':
      isHandsetPortrait ||
      isTabletPortrait ||
      isTabletLandscape ||
      isHandsetLandscape, 'first': isHandsetPortrait ||
      isTabletPortrait ||
      isTabletLandscape ||
      isHandsetLandscape
  }">
    <div class="right-content" [ngClass]="{
      'right-content-handset-portrait':
        isHandsetPortrait ||
        isTabletPortrait ||
        isTabletLandscape ||
        isHandsetLandscape
    }">
      <div class="content-right" [ngClass]="{
        'content-right-handset-portrait':
          isHandsetPortrait ||
          isTabletPortrait ||
          isTabletLandscape ||
          isHandsetLandscape
      }">
        <div class="right-title">Taking Care of The Day
          to Day</div>
        <div class="first-row">
          Our expert, agile team is based in Christchurch with easy
          access to a number of popular residential locations including
          Hengistbury Head, Mudeford, Poole and Bournemouth.
          Being based here means we can deal with the day-to-day
          details of a build, so you don’t have to. We’ll take care of
          everything and send you regular reports so you can spend
          less time on admin and more time on the things that matter
          most to you.
        </div>
        <div class="right-title">With You Every Step of
          the Way</div>
        <div class="first-row">
          With so much choice and multiple decisions to make,
          managing a home build, renovation or refurbishment can be
          overwhelming. <br> <br>
          We’re here to take the stress away and make the process
          as smooth as possible.
        </div>
      </div>
    </div>
    <div class="left-content" [ngClass]="{
      'left-content-handset-portrait':
        isHandsetPortrait ||
        isTabletPortrait ||
        isTabletLandscape ||
        isHandsetLandscape
    }">
      <img loading="lazy" [src]="aboutData.image.data[3].attributes.url" alt="Boxes labeled with different construction project addresses" />
    </div>
  </div>
  <div class="section maintenance" [ngClass]="{
    'section-handset-portrait':
      isHandsetPortrait ||
      isTabletPortrait ||
      isTabletLandscape ||
      isHandsetLandscape
  }">
    <div class="left-content" [ngClass]="{
      'left-content-handset-portrait':
        isHandsetPortrait ||
        isTabletPortrait ||
        isTabletLandscape ||
        isHandsetLandscape
    }">
      <img loading="lazy" [src]="aboutData.image.data[4].attributes.url" alt="Wooden boards and a plant in broad construction offices" />
    </div>
    <div class="right-content" [ngClass]="{
      'right-content-handset-portrait':
        isHandsetPortrait ||
        isTabletPortrait ||
        isTabletLandscape ||
        isHandsetLandscape
    }">
      <div class="content-right" [ngClass]="{
      'content-right-handset-portrait':
        isHandsetPortrait ||
        isTabletPortrait ||
        isTabletLandscape ||
        isHandsetLandscape
    }">
        <div class="icon">
          <svg class="logo" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="125" viewBox="0 0 24 24"
            width="125">
            <rect fill="none" height="24" width="24" />
            <path
              d="M5.77,7.15L7.2,4.78l1.03-1.71c0.39-0.65,1.33-0.65,1.72,0l1.48,2.46l-1.23,2.06L9.2,9.21L5.77,7.15z M21.72,12.97 l-1.6-2.66l-3.46,2L18.87,16H20c0.76,0,1.45-0.43,1.79-1.11C21.93,14.61,22,14.31,22,14C22,13.64,21.9,13.29,21.72,12.97z M16,21 h1.5c0.76,0,1.45-0.43,1.79-1.11L20.74,17H16v-2l-4,4l4,4V21z M10,17H5.7l-0.84,1.41c-0.3,0.5-0.32,1.12-0.06,1.65l0,0 C5.08,20.63,5.67,21,6.32,21H10V17z M6.12,14.35l1.73,1.04L6.48,9.9L1,11.27l1.7,1.02l-0.41,0.69c-0.35,0.59-0.38,1.31-0.07,1.92 l1.63,3.26L6.12,14.35z M17.02,5.14l-1.3-2.17C15.35,2.37,14.7,2,14,2h-3.53l3.12,5.2l-1.72,1.03l5.49,1.37l1.37-5.49L17.02,5.14z" />
          </svg>
        </div>
        <div class="right-title last">Caring for properties and the planet.</div>
        <div>
          <div class="first-row">
            As well as working with suppliers to enhance energy efficiency,
            future-proof homes, reduce waste and use sustainable materials. <br> <br>
            We also plant trees for every property we complete.</div>
        </div>
      </div>
    </div>
  </div>