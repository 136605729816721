import { transition, trigger, useAnimation } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, interval } from 'rxjs';
import { HomepageImagesService } from 'src/app/services/homepage-images/homepage-images.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { fadeOut, slideIn, slideOut } from './carousel.animation';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [
        useAnimation(slideIn, { params: { time: '800ms' } }),
      ]),
      transition('* => void', [
        useAnimation(slideOut, { params: { time: '800ms' } }),
      ]),
    ]),
    trigger('outroAnimation', [
      transition('* => void', [
        useAnimation(fadeOut, { params: { time: '200ms' } }),
      ]),
    ]),
  ],
})
export class CarouselComponent implements OnInit {
  private intervalSubscription: Subscription = new Subscription();
  private intervalCount = 0;
  private intervalTime = 6000; // Interval time in milliseconds
  public currentImage = 0;
  public isTabletPortrait = false;
  public isTabletLandscape = false;
  public isHandsetPortrait = false;
  public isHandsetLandscape = false;
  public carouselRestarting = true;
  private swipeCoord?: [number, number];
  private swipeTime?: number;

  public images: any[] = [];

  constructor(
    private responsive: BreakpointObserver,
    public loadingService: LoadingService,
    public homepageImagesService: HomepageImagesService,
    private router: Router,
    public translate: TranslateService, 
    @Inject(PLATFORM_ID) private platformId: Object,

  ) { }
  private breakpoint: string = '';
  ngOnInit(): void {
    this.loadingService.show();
    this.getHomepageImages();

    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        this.isTabletPortrait = false;
        this.isTabletLandscape = false;
        this.isHandsetPortrait = false;
        this.isHandsetLandscape = false;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.isTabletPortrait = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.isTabletLandscape = true;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.isHandsetPortrait = true;
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.isHandsetLandscape = true;
        }
      });
  }
  getHomepageImages() {
    this.homepageImagesService.getImages().subscribe({
      next: (images: any) => {
        this.images = images.data.attributes.image.data;
        this.loadingService.hide();
        this.carouselRestarting = false;
        this.startCarouselInterval();
      },
      error: (err) => {
        this.loadingService.hide();
      },
    });
  }

  startCarouselInterval() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.carouselRestarting = false;
      }, 800);
      this.intervalSubscription = interval(this.intervalTime).subscribe(() => {
        if (!this.carouselRestarting) {
          this.onNextClick();
        }
        this.intervalCount++;
      });
    }
  }

  onPreviousClick() {
    if (this.carouselRestarting) this.restartCarouselInterval();

    this.carouselRestarting = true;
    const previous = this.currentImage - 1;
    this.currentImage = previous < 0 ? this.images.length - 1 : previous;
    this.restartCarouselInterval();
  }

  onNextClick() {
    if (this.carouselRestarting) this.restartCarouselInterval();

    this.carouselRestarting = true;
    const next = this.currentImage + 1;
    this.currentImage = next === this.images.length ? 0 : next;
    this.restartCarouselInterval();
  }

  restartCarouselInterval() {
    this.intervalSubscription.unsubscribe();
    this.startCarouselInterval();
  }

  goToProject(index: number) {
    this.currentImage = index;
    this.restartCarouselInterval();
  }

  navigateToProject(projectTitle: string) {
    this.router.navigate(['projects', projectTitle]);
  }


  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY,
    ];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [
        coord[0] - this.swipeCoord![0],
        coord[1] - this.swipeCoord![1],
      ];
      const duration = time - this.swipeTime!;

      if (
        duration < 1000 && //
        Math.abs(direction[0]) > 30 && // Long enough
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        if (swipe === 'next') {
          this.onNextClick();
        } else if (swipe === 'previous') {
          this.onPreviousClick();
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.intervalSubscription.unsubscribe();
  }
}
