import { animate, animation, style } from '@angular/animations';

export const slideIn = animation([
  style({
    transform: 'translate3d(100vw, 0, 0)',
  }), // start state
  animate(
    '{{time}} cubic-bezier(0.62, 0, 0.37, 0.95)',
    style({
      transform: 'translate3d(0, 0, 0)',
    })
  ),
]);
export const slideOut = animation([
  animate(
    '{{time}} cubic-bezier(0.62, 0, 0.37, 0.95)',
    style({
      transform: 'translate3d(-100vw, 0, 0)',
    })
  ),
]);
export const fadeOut = animation([
  animate('{{time}} cubic-bezier(0.62, 0, 0.37, 0.95)', style({ opacity: 0 })),
]);
export const zoomIn = animation([
  style({
    transform: 'scale3d(1, 1, 1)',
    overflow: 'hidden',
  }), // start state
  animate(
    '{{time}}',
    style({
      transform: 'scale3d(1.05, 1.05, 1.05)',
    })
  ),
]);

export const slideFadeIn = animation([
  style({
    transform: 'translate3d(-224px, 0, 0)',
    '-webkit-transform': 'translate3d(-224px, 0, 0)',
    opacity: 0,
  }), // start state
  animate(
    '{{delay}}',
    style({
      transform: 'translate3d(-224px, 0, 0)',
      '-webkit-transform': 'translate3d(-224px, 0, 0)',
      opacity: 0,
    })
  ), // Add a delay
  animate(
    '{{time}} cubic-bezier(0.62, 0, 0.37, 0.95)',
    style({
      transform: 'translate3d(0, 0, 0)',
      '-webkit-transform': 'translate3d(0, 0, 0)',
      opacity: 1,
    })
  ),
]);

export const slideFadeOut = animation([
  animate(
    '{{time}} cubic-bezier(0.62, 0, 0.37, 0.95)',
    style({
      transform: 'translate3d(0, 224px, 0)',
      '-webkit-transform': 'translate3d(0, 224px, 0)',
      opacity: 0,
    })
  ),
]);
