import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private aboutURL = 'https://broadconstruction.onrender.com/api/addresses';
  httpOptions: any;

  constructor(private http: HttpClient) {}

  getAddresses() {
    const params = new HttpParams()
      .set('pagination[page]', 1)
      .set('pagination[pageSize]', 100);
    return this.http.get(this.aboutURL, { params });
  }
}
