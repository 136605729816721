import { transition, trigger, useAnimation } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, Inject, PLATFORM_ID, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { ProjectService } from 'src/app/services/project/project.service';
import { slideIn, slideOut } from '../carousel/carousel.animation';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectDialogComponent } from './project-dialog/project-dialog.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition('void => *', [
        useAnimation(slideIn, { params: { time: '800ms' } }),
      ]),
      transition('* => void', [
        useAnimation(slideOut, { params: { time: '800ms' } }),
      ]),
    ]),
  ],
})
export class ProjectComponent {
  public isTabletPortrait = false;
  public isTabletLandscape = false;
  public isHandsetPortrait = false;
  public isHandsetLandscape = false;
  public project: any;
  public images: any;
  public showInfo = true;
  public infoButton = 'HIDE INFORMATION';
  private swipeCoord?: [number, number];
  private swipeTime?: number;

  constructor(
    private route: ActivatedRoute,
    private responsive: BreakpointObserver,
    private projectService: ProjectService,
    public loadingService: LoadingService,
    public dialog: MatDialog
  ) {}
  private projectId: number = 0;

  ngOnInit(): void {
    this.getProjectId();
    this.loadingService.show();
    this.getProject(this.projectId);

    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        // Reset all breakpoints to false
        this.isTabletPortrait = false;
        this.isTabletLandscape = false;
        this.isHandsetPortrait = false;
        this.isHandsetLandscape = false;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.isTabletPortrait = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.isTabletLandscape = true;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.isHandsetPortrait = true;
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.isHandsetLandscape = true;
        }
      });
  }

  getProjectId() {
    this.route.params.subscribe((params) => {
      this.projectId = params['id'];
    });
  }

  switchInfoDisplay() {
    this.showInfo = !this.showInfo;
  }

  getProject(id: number) {
    this.projectService.getProject(id).subscribe({
      next: (project: any) => {
        this.project = project.data.attributes;
        this.images = project.data.attributes.images.data;
        this.loadingService.hide();
      },
      error: (err) => {},
    });
  }

  @ViewChildren('imgElement') imgElements!: QueryList<ElementRef<HTMLImageElement>>;

ngAfterViewInit() {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      console.log(
        `Image ${entry.target.getAttribute('alt')} is ${
          entry.isIntersecting ? 'in' : 'out of'
        } the viewport`
      );
    });
  });

  this.imgElements.forEach((img) => observer.observe(img.nativeElement));
}


  openImage(url: string) {
    const dialogRef = this.dialog.open(ProjectDialogComponent, {
      data: { url },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
