<div #overview class="container" [ngClass]="{ 'container-handset-portrait': isHandsetPortrait }">
  <article [ngClass]="{ 'article-handset-portrait': isHandsetPortrait }">
    <h1 class="title" [ngClass]="{ 'title-handset-portrait': isHandsetPortrait }">
      Built around you
    </h1>
    <div class="content">
      <div class="paragraph" [ngClass]="{ 'paragraph-handset-portrait': isHandsetPortrait }">
        We’re here to help make your dream property project a reality.
      </div>
      <div class="paragraph" [ngClass]="{ 'paragraph-handset-portrait': isHandsetPortrait }">
        As one of the South Coast’s leading residential construction management companies,
        we specialise in bringing homes to life from concept to completion.
        Working across renovations, refurbishments and new-build, our team is dedicated to
        creating stylish spaces designed around you.
      </div>
      <div class="contact-us" [ngClass]="{ 'paragraph-handset-portrait': isHandsetPortrait }">
        <button aria-label="Go to contact page" mat-button  class="override"
          [ngClass]="{ 'override-handset-portrait': isHandsetPortrait }">
          <a routerLink="/contact">Tell us about your project</a>
        </button>
      </div>
    </div>
  </article>
</div>