import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('0.5s 0.1s ease-in-out')]),
      transition(':leave', [
        animate('0.5s 0.1s ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
    trigger('fadeIn2', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('0.5s 0.2s ease-in-out')]),
    ]),
    trigger('fadeIn3', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('0.5s 0.3s ease-in-out')]),
    ]),
    trigger('fadeIn4', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('0.5s 0.4s ease-in-out')]),
    ]),
    trigger('fadeIn5', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('0.5s 0.5s ease-in-out')]),
    ]),
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ scale: 0, opacity: 0 }),
        animate(
          '500ms cubic-bezier(0.25, 0.46, 0.45, 0.94)',
          style({ scale: 1.3, rotate: '180deg', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        style({ scale: 1.3, opacity: 1 }),
        animate(
          '500ms cubic-bezier(0.25, 0.46, 0.45, 0.94)',
          style({ scale: 0, rotate: '180deg', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  public isTabletPortrait = false;
  public isTabletLandscape = false;
  public isHandsetPortrait = false;
  public isHandsetLandscape = false;
  public menuToggled = false;
  public isBackgroundVisible = true;
  private breakpoint: string = '';

  ngOnInit(): void {
    this.responsive
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        // Reset all breakpoints to false
        this.isTabletPortrait = false;
        this.isTabletLandscape = false;
        this.isHandsetPortrait = false;
        this.isHandsetLandscape = false;

        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.isTabletPortrait = true;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.isTabletLandscape = true;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.isHandsetPortrait = true;
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.isHandsetLandscape = true;
        }
      });
  }

  constructor(private router: Router, private responsive: BreakpointObserver) {}

  public whiteLogo = './../../../assets/logo/logo-white-transparent.webp';
  public brownLogo = './../../../assets/logo/logo-brown-transparent.webp';

  isOnHomePage(): boolean {
    return this.router.url === '/';
  }
  isOnAboutPage(): boolean {
    return this.router.url === '/about';
  }
  isOnMapPage(): boolean {
    return this.router.url === '/map';
  }
  isOnServicesPage(): boolean {
    return this.router.url === '/services';
  }
  isOnProjectPage(): boolean {
    return this.router.url.startsWith('/projects/');
  }

  toggleMenu(open?: string) {
    if (open === 'close') {
      this.menuToggled = false;
    } else {
      this.menuToggled = !this.menuToggled;
    }
  }

  isActive(route: string): boolean {
    return this.router.url === route;
  }
}
