<div
  class="container"
  [ngClass]="{ 'container-handset-portrait': isHandsetPortrait }"
>
  <div class="background-image">
    <img loading="lazy" src="assets/images/reviews.webp" alt="Drone shot of a beautiful riverside house with a bridge" />
  </div>
  <div class="carousel">
    <div class="slide" *ngFor="let review of reviews; let i = index">
      <ng-container *ngIf="i === currentReview">
        <div
          class="review"
          [ngClass]="{ 'review-handset-portrait': isHandsetPortrait }"
        >
          <span class="quotation">"</span>{{ review.attributes.review
          }}<span class="quotation">"</span>
        </div>
      </ng-container>
    </div>
    <button
      aria-label="Show previous review"
      class="control prev"
      (click)="onPreviousClick()"
      [disabled]="carouselRestarting"
    >
      <span class="arrow left"></span>
    </button>
    <button
      aria-label="Show next review"
      class="control next"
      (click)="onNextClick()"
      [disabled]="carouselRestarting"
    >
      <span class="arrow right"></span>
    </button>
  </div>
  <div class="slides">
    <div *ngFor="let review of reviews; let i = index">
      <div class="slide-container" (click)="goToReview(i)">
        <div
          class="slide-line"
          [ngClass]="{
            active: i === currentReview,
            'slide-line-smaller': isHandsetPortrait || isTabletPortrait
          }"
        ></div>
      </div>
    </div>
  </div>
</div>
