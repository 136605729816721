import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AboutService {
  private aboutURL = 'https://broadconstruction.onrender.com/api/about';
  httpOptions: any;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      params: { populate: 'image' },
    };
  }

  getImage() {
    return this.http.get(this.aboutURL, this.httpOptions);
  }
}
