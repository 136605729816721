<mat-toolbar class="toolbar" [ngClass]="{ 'toolbar-portrait': isHandsetPortrait || isTabletPortrait }">
  <div class="careers">
    <div class="careers-title">CAREERS</div>
    <div class="careers-cta">JOIN OUR TEAM</div>
    <div class="careers-description">
      We currently do not have any job vacancies. However, please send your CV
      and cover letter and we will add to our talent pool.
    </div>
    <div class="careers-link">
      <a routerLink="/jobs">APPLY NOW <mat-icon>arrow_right_alt</mat-icon></a>
    </div>
  </div>
  <div class="menu" [ngClass]="{ 'menu-portrait': isHandsetPortrait || isTabletPortrait }">
    <div class="menu-column">
      <div class="menu-title">News</div>
      <ul *ngFor="let news of news">
        <li><a class="news-title" [routerLink]="['/news', news.id, news.slug]">{{ news.attributes.Title }}</a></li>
      </ul>
    </div>
    <div class="menu-column">
      <div class="menu-title">Projects</div>
      <ul>
        <li><a routerLink="/projects">Latest projects</a></li>
      </ul>
    </div>
    <div class="menu-column">
      <div class="menu-title">Information</div>
      <ul>
        <li><a routerLink="/about">About</a></li>
        <li><a routerLink="/areas-we-cover">Areas we cover</a></li>
        <li><a routerLink="/privacy">Privacy policy</a></li>
        <li><a routerLink="/jobs">Work with us</a></li>
      </ul>
    </div>
    <div class="menu-column">
      <div class="menu-title">Contact</div>
      <ul>
        <li class="contact-info">
          <a href="http://maps.google.com/?q=Unit 4B Wilverley Road, Christchurch, BH23 3RU, Dorset, England"
            target="_blank">
            <p>Broad Construction LTD</p>
            <p>Unit 4b, Wilverley Road</p>
            <p>Christchurch, Dorset, BH23 3RU</p>
          </a>
        </li>
        <li class="contact-info"><a href="tel:01202901422">Telephone: 01202 901422</a></li>
        <li class="contact-info"><a href="mailto:info@broadconstruction.co.uk" target="_blank">
            Email:
            info&#64;broadconstruction.co.uk
          </a></li>
        <li class="icons">
          <a [ngClass]="{ 'handset-portrait': isHandsetPortrait }" aria-label="facebook icon"
            href="https://www.facebook.com/broadconstructionUK/" target="_blank">
            <img loading="lazy" class="icon" src="assets/icons/facebook.svg" alt="facebook logo" />
          </a>
          <a [ngClass]="{ 'handset-portrait': isHandsetPortrait }" aria-label="instagram icon"
            href="https://www.instagram.com/broad_construction/?hl=en" target="_blank">
            <img loading="lazy" class="icon" src="assets/icons/instagram.svg" alt="instagram logo" />
          </a>
          <a [ngClass]="{ 'handset-portrait': isHandsetPortrait }" aria-label="linkedin icon"
            href="https://www.linkedin.com/company/broadconstruction" target="_blank">
            <img loading="lazy" class="icon" src="assets/icons/linkedin.svg" alt="linkedin logo" />
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- <div class="slide-toggle">
    <mat-slide-toggle [(ngModel)]="isChecked" (toggleChange)="toggleTheme()">{{
      isChecked
    }}</mat-slide-toggle>
  </div> -->
  <img loading="lazy" class="iso" src="assets/images/iso.webp" alt="iso regulation logo" />

  <div class="socials">
    Find us on:<a href="https://www.facebook.com/BroadConstructionUK" target="_blank">Facebook</a><a [href]="
        'https://www.instagram.com/broad_construction/?hl=en'
      " target="_blank">Instagram</a><a
      href="https://wa.me/447378303545?text=Hello,%20I'm%20interested%20in%20your%20services"
      target="_blank">WhatsApp</a><a href="mailto:info@broadconstruction.co.uk" target="_blank">
      Email
    </a>


  </div>
  <div class="copyright" [ngClass]="{
      'copyright-small-screen': isHandsetPortrait || isTabletPortrait
    }">
    <div>© 2025 Broad Construction LTD. All Rights Reserved.</div>
    <a [ngClass]="{
      'address-small-screen': isHandsetPortrait || isTabletPortrait
    }" href="http://maps.google.com/?q=Unit 4B Wilverley Road, Christchurch, BH23 3RU, Dorset, England"
      target="_blank">
      <div>Registered Office Address: Unit 4b, Wilverley Road,</div>
      <div class="address-part-2">Christchurch, Dorset, BH23 3RU</div>
    </a>
    <div>&nbsp; | VAT NUMBER: 236 1054 38 | &nbsp;</div>
    <div>Company Number: 09967491 | Designed by Yves Molina.</div>
  </div>
  <div class="data">
    <div class="data-link"><a routerLink="/privacy">Privacy policy</a></div>
    <div class="data-link" (click)="openCookieService()">Cookie consent</div>
  </div>
</mat-toolbar>