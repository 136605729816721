<div class="spinner-container" *ngIf="loadingService.isLoading | async">
  <mat-spinner color="accent"></mat-spinner>
</div>
<div class="container" *ngIf="!(loadingService.isLoading | async)">
  <h1 [ngClass]="{
      'projects-title': true,
      'projects-title-tablet-landscape': isTabletLandscape,
      'projects-title-handset-landscape': isHandsetLandscape,
      'projects-title-tablet-portrait': isTabletPortrait,
      'projects-title-handset-portrait': isHandsetPortrait
    }">Residential Construction Projects
  </h1>
  <mat-grid-list class="list" [cols]="gridCols" rowHeight="2:1" [gutterSize]="'3mm'">
    <mat-grid-tile *ngFor="let project of projects; let i = index">
      <div class="grid-tile-wrapper">
        <img class="grid-img" [attr.loading]="i < 4 ? 'eager' : 'lazy'" class="grid-img"
          [alt]="'Modern house design in ' + project.attributes.title "
          [src]="project.attributes.images.data[0].attributes.url"
          [routerLink]="['/projects', project.id, project.slug]" />
        <a class="middle-band" [routerLink]="['/projects', project.id, project.slug]">
          <div class="title" [ngClass]="{ 'title-handset-portrait': isHandsetPortrait }">
            {{ project.attributes.title }}
          </div>
          <div class="project-type" [ngClass]="{ 'project-type-handset-portrait': isHandsetPortrait }">
            {{ project.attributes.projectType }}
          </div>
        </a>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>