<div class="container">
  <div class="section">
    <h1 class="main-title">Privacy policy</h1>
    <p>
      Your privacy is important to us. This Privacy Policy covers what we
      collect and how we use, disclose, transfer and store your information.
    </p>

    <div class="sub-title">What information do we collect?</div>
    <p>We collect information from you if:</p>
    <ul>
      <li>you submit an enquiry from our contact page</li>
    </ul>
    <div class="sub-title">What do we use your information for?</div>
    <p>
      Any of the information we collect from you may be used in one or more of
      the following ways:
    </p>
    <ul>
      <li>
        to respond via supplied contact details regarding your enquiry (email,
        telephone or post)
      </li>
      <li>
        to personalise your experience (your information helps us to better
        respond to your individual needs)
      </li>
      <li>
        to improve our website (we continually strive to improve our website
        offerings based on the information and feedback we receive from you)
      </li>
      <li>
        to send periodic emails about our company, product news or service
        information, etc.
      </li>
    </ul>
    <div class="sub-title">How do we protect your information?</div>
    <div class="small-title">Submitted enquires (from our contact page)</div>
    <p>
      Data is stored on our server which has security measures in place. The
      recipient computer / device email client is protected by password
      encryption.
    </p>

    <div class="sub-title">
      Do we disclose any information to outside parties?
    </div>
    <p>
      We do not sell, trade, or otherwise transfer to outside parties any
      personally identifiable information. This does not include trusted third
      parties who assist us in operating our website, conducting our business,
      or servicing you. Such trusted parties may have access to personally
      identifiable information on a need-to-know basis and will be contractually
      obliged to keep your information confidential. We may also release your
      information when we believe release is appropriate to comply with the law,
      enforce our site policies, or protect ours or others rights, property, or
      safety. However, non-personally identifiable visitor information may be
      provided to other parties for marketing, advertising, or other uses.
    </p>

    <div class="sub-title">Third party links</div>
    <p>
      Occasionally, at our discretion, we may include or offer third party
      products or services on our website. These third party sites have separate
      and independent privacy policies. We therefore have no responsibility or
      liability for the content and activities of these linked sites.
      Nonetheless, we seek to protect the integrity of our site and welcome any
      feedback about these sites.
    </p>

    <div class="sub-title">Your Consent</div>
    <p>By using our site, you consent to this privacy policy.</p>

    <div class="sub-title">Changes to our Privacy Policy</div>
    <p>
      If we decide to change our privacy policy, we will post those changes on
      this page, and/or update the Privacy Policy modification date below.
    </p>
    <p>February 11th, 2024.</p>

    <div class="sub-title">Contacting us (GDPR Data Officer)</div>
    <p>
      If there are any questions regarding this privacy policy you may contact
      our GDPR Data Officer.
    </p>

    <div class="sub-title">How we use cookies</div>
    <p>
      In compliance with GDPR legislation we give you control over cookie usage
      on this website.
    </p>
  </div>
</div>
